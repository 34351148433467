import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Checkbox,
  Card,
  TimePicker,
  Timeline
} from "antd";
import { Link } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import ProfileFormActionSpace from "../../components/ProfileFormActionSpace";

/**
 * Building details
 * @component
 * @alias BuildingDetails
 * @property {object} building - contains building details
 * @returns Structured content of the building in <Row(s) /> and <Col(s) />
 */
const BuildingDetails = ({ building, setBuilding }) => {
  // Translations
  const { t } = useTranslation();
  const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

  // States
  const [endTags, setEndTags] = useState([]);
  const [isFormReadOnly, setFormReadOnly] = useState(true);
  const [isFormSending, setFormSending] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [unpaidInvoices, setUnpaidInvoices] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);

  // Forms
  const [cancelBuildingForm] = Form.useForm();
  const [buildingForm] = Form.useForm();

  // Row gutter
  const gutter = [8, 0];

  useEffect(() => {
    let unmounted = false;

    coreApi
      .get("buildings/end-tags")
      .then((res) => {
        if (!unmounted) setEndTags(() => [...res.data]);
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    return () => {
      unmounted = true;
    };
  }, [building]);

  const finishAction = (values) => {
    setFormSending(true);
    coreApi
      .put("/buildings/" + values.building_id, {
        ...values,
        ending_date: values.ending_date?.format("YYYY-MM-DD") || null,
      })
      .then((response) => {
        setFormReadOnly(true);
        notification.success({
          message: response.data.message,
        });
      })
      .catch((error) => {
        notification.error({ message: error.response.data.message });
      })
      .finally(() => {
        setFormSending(false);
      });
  };

  const handleCancelBuildingClick = () => {
    setIsCancelModalVisible(true);
    coreApi
      .get(`/buildings/${building?.id}/unpaid-invoices`)
      .then((response) => {
        setUnpaidInvoices(response.data);
        setIsConfirmed(response.data.length === 0);
      })
      .catch((error) => {
        notification.error({ message: error.response.data.message });
      });
  };

  return (
    <React.Fragment key={building?.id}>
      <Form form={buildingForm} name="building" onFinish={(values) => finishAction(values)}>
        <Row align="middle" gutter={gutter}>
          <Col>
            <Form.Item hidden name="building_id" initialValue={building?.id}>
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item hidden name="client_id" initialValue={building?.client_id}>
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item hidden name="city_branch_id" initialValue={building?.city_branch_id}>
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col span={24}>
            <div style={{ float: "right", display: "flex", gap: "12px", marginBottom: "16px" }}>
              {!isFormReadOnly && !building?.ending_date && (
                <React.Fragment>
                  <Modal
                    key={building?.id}
                    title={t("buildings.cancelModalTitle")}
                    open={isCancelModalVisible}
                    okType="danger"
                    okText={t("buildings.cancelBuilding")}
                    cancelText={t("common.cancel")}
                    onOk={() => {
                      cancelBuildingForm.submit();
                    }}
                    onCancel={() => {
                      setIsCancelModalVisible(false);
                    }}
                    okButtonProps={{ disabled: !isConfirmed }}
                  >
                    <Form
                      form={cancelBuildingForm}
                      name="building"
                      onFinish={(values) => {
                        coreApi
                          .put(`/buildings/${building?.id}/cancel`, {
                            ...values,
                            ending_date: values.ending_date?.format("YYYY-MM-DD"),
                          })
                          .then((response) => {
                            cancelBuildingForm.resetFields();

                            setIsCancelModalVisible(false);
                            setFormReadOnly(true);
                            setBuilding({ ...building, ...response.data.building });
                            buildingForm.resetFields();

                            notification.success({
                              message: response.data.message,
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                          })
                          .finally(() => {
                            setFormSending(false);
                          });
                      }}
                    >
                      <Row gutter={gutter}>
                        <Col span={24}>
                          <Form.Item
                            name="ending_date"
                            label={t("buildings.endingDate")}
                            initialValue={building?.ending_date ? moment(building?.ending_date) : null}
                          >
                            <DatePicker></DatePicker>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="end_of_cooperation_tag_id"
                            label={t("buildings.endingTag")}
                            initialValue={building?.end_of_cooperation_tag_id}
                          >
                            <Select>
                              {endTags?.map((tag) => (
                                <Select.Option
                                  value={tag.end_of_cooperation_tag_id}
                                  key={tag.end_of_cooperation_tag_id}
                                >
                                  {tag.tag_name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="ending_description"
                            label={t("buildings.endingDescription")}
                            initialValue={building?.ending_description}
                          >
                            <TextArea></TextArea>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                    {unpaidInvoices.length > 0 ? (
                      <>
                        <div style={{ color: "red", marginBottom: "8px" }}>
                          {t("buildings.unpaidInvoiceMessage")}
                          <Checkbox
                            style={{ marginLeft: "10px" }}
                            checked={isConfirmed}
                            onChange={(e) => setIsConfirmed(e.target.checked)}
                          ></Checkbox>
                        </div>
                        <div style={{ overflowX: "auto", whiteSpace: "nowrap", padding: "10px 0" }}>
                          {unpaidInvoices.map((invoice) => (
                            <Link key={invoice.id} to={"/invoices/" + invoice.id}>
                              {invoice.invoice_number}
                            </Link>
                          )).reduce((prev, curr) => [prev, ", ", curr])}
                        </div>
                      </>
                    ) : (
                      <div style={{ marginBottom: "8px" }}>
                        {t("buildings.allPaidMessage")}
                        <Checkbox style={{ marginLeft: "10px" }} checked={isConfirmed}></Checkbox>
                      </div>
                    )}
                  </Modal>

                  <Button danger onClick={handleCancelBuildingClick}>
                    {t("buildings.cancelBuilding")}
                  </Button>
                </React.Fragment>
              )}

              <ProfileFormActionSpace
                formInstance={buildingForm}
                isFormReadOnly={isFormReadOnly}
                isFormSending={isFormSending}
                setFormEditable={() => setFormReadOnly(false)}
                setFormDisabled={() => setFormReadOnly(true)}
              />
            </div>
          </Col>
        </Row>

        {/* Adresa */}
        <Card
          size="small"
          title={t("common.address")}
          headStyle={{ backgroundColor: "#f0f0f0" }}
          style={{ marginBottom: 24 }}
          className="table-striped"
        >
          <Row align="middle" gutter={gutter}>
            <Col span={4} style={{ fontWeight: "bold" }}>
              {t("buildings.city")}:
            </Col>
            <Col span={10}>
              <Form.Item name="city" initialValue={building?.city}>
                <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly} />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={gutter}>
            <Col span={4} style={{ fontWeight: "bold" }}>
              {t("buildings.district")}:
            </Col>
            <Col span={10}>
              <Form.Item name="district" initialValue={building?.district}>
                <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly} />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={gutter}>
            <Col span={4} style={{ fontWeight: "bold" }}>
              {t("buildings.postalCode")}:
            </Col>
            <Col span={10}>
              <Form.Item name="postal_code" initialValue={building?.postal_code}>
                <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly} />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        {/* Poznámky k budově a speciální požadavky */}
        <Card
          size="small"
          title={t("buildings.notes")}
          headStyle={{ backgroundColor: "#f0f0f0" }}
          style={{ marginBottom: 24 }}
          className="table-striped"
        >
          <Row align="middle" gutter={gutter}>
            <Col span={4} style={{ fontWeight: "bold" }}>
              {t("buildings.cleaningRoomDescription")}:
            </Col>
            <Col span={10}>
              <Form.Item
                name="cleaning_room"
                initialValue={building?.cleaning_room}
              >
                <TextArea readOnly={isFormReadOnly} bordered={!isFormReadOnly} autoSize={{ minRows: 1, maxRows: 6 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={gutter}>
            <Col span={4} style={{ fontWeight: "bold" }}>
              {t("buildings.buildingNotes")}:
            </Col>
            <Col span={10}>
              <Form.Item name="notes" initialValue={building?.notes}>
                <TextArea readOnly={isFormReadOnly} bordered={!isFormReadOnly} autoSize={{ minRows: 1, maxRows: 6 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={gutter}>
            <Col span={4} style={{ fontWeight: "bold" }}>
              {t("buildings.keys")}:
            </Col>
            <Col span={10}>
              <Form.Item
                name="number_of_keys"
                initialValue={building?.number_of_keys}
              >
                <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly} />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={gutter}>
            <Col span={4} style={{ fontWeight: "bold" }}>
              {t("buildings.requestedDay")}:
            </Col>
            <Col span={10}>
              <Form.Item
                name="requested_day"
                initialValue={building?.requested_day ? JSON.parse(building.requested_day) : []}
              >
                <Select
                  mode="multiple"
                  disabled={isFormReadOnly}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {days.map((day) => (
                    <Select.Option key={day} value={day}>
                      {t("rrules.byweekday." + day)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={gutter}>
            <Col span={4} style={{ fontWeight: "bold" }}>
              {t("buildings.requestedTime")}:
            </Col>
            <Col span={10}>
              <Form.Item
                name="requested_time"
                initialValue={building?.requested_time ? JSON.parse(building?.requested_time).map(time => moment(time)) : []}
              >
                <TimePicker.RangePicker
                  disabled={isFormReadOnly}
                  bordered={!isFormReadOnly}
                  format="HH:mm"
                  use12Hours={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={gutter}>
            <Col span={4} style={{ fontWeight: "bold" }}>
              {t("buildings.dayTimePreferenceNote")}:
            </Col>
            <Col span={8}>
              <Form.Item name="requested_day_time_preference_note"
                         initialValue={building?.requested_day_time_preference_note}>
                <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly} />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={gutter}>
            <Col span={4} style={{ fontWeight: "bold" }}>
              {t("buildings.specificRequirements")}:
            </Col>
            <Col span={10}>
              <Form.Item name="specific_request_note" initialValue={building?.specific_request_note}>
                <TextArea readOnly={isFormReadOnly} bordered={!isFormReadOnly} autoSize={{ minRows: 1, maxRows: 6 }} />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        {/* Schůze */}
        <Card
          size="small"
          title={t("buildings.meeting")}
          headStyle={{ backgroundColor: "#f0f0f0" }}
          style={{ marginBottom: 24 }}
          className="table-striped"
        >
          <Row align="middle" gutter={gutter}>
            <Col span={4} style={{ fontWeight: "bold" }}>
              {t("buildings.scheduledMeetingDate")}:
            </Col>
            <Col span={10}>
              <Form.Item
                name="scheduled_meeting_date"
                initialValue={building?.scheduled_meeting_date ? moment(building?.scheduled_meeting_date) : null}
              >
                <DatePicker disabled={isFormReadOnly} bordered={!isFormReadOnly} />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        {/* Ukončení objednávky */}
        <Card
          size="small"
          title={t("buildings.orderTermination")}
          headStyle={{ backgroundColor: "#f0f0f0" }}
          style={{ marginBottom: 24 }}
          className="table-striped"
        >
          <Row align="middle" gutter={gutter}>
            <Col span={4} style={{ fontWeight: "bold" }}>
              {t("buildings.endingDate")}:
            </Col>
            <Col span={10}>
              <Form.Item
                name="ending_date"
                initialValue={building?.ending_date ? moment(building?.ending_date) : null}
              >
                <DatePicker disabled={isFormReadOnly} bordered={!isFormReadOnly} />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={gutter}>
            <Col span={4} style={{ fontWeight: "bold" }}>
              {t("buildings.endingTag")}:
            </Col>
            <Col span={10}>
              <Form.Item
                name="end_of_cooperation_tag_id"
                initialValue={building?.end_of_cooperation_tag_id}
              >
                <Select disabled={isFormReadOnly} bordered={!isFormReadOnly}>
                  {endTags?.map((tag) => (
                    <Select.Option value={tag.end_of_cooperation_tag_id} key={tag.end_of_cooperation_tag_id}>
                      {tag.tag_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" gutter={gutter}>
            <Col span={4} style={{ fontWeight: "bold" }}>
              {t("buildings.endingDescription")}:
            </Col>
            <Col span={10}>
              <Form.Item
                name="ending_description"
                initialValue={building?.ending_description}
              >
                <TextArea readOnly={isFormReadOnly} bordered={!isFormReadOnly} autoSize={{ minRows: 1, maxRows: 6 }} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </React.Fragment>
  );
};

export default BuildingDetails;
