import { Col, List, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../api/calls";
import Contact from "./Contact";
import ContactAddForm from "./ContactAddForm";

const ContactsPane = ({ props, contacts, contactable, allContacts = null }) => {
  // Translations
  const { t } = useTranslation();

  // States
  const [contactsState, setContactsState] = useState([]);
  const [tags, setTags] = useState([]);

  // Get tags
  useEffect(() => {
    coreApi
      .get("contacts/tags")
      .then((response) => {
        setTags(response.data);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    setContactsState(contacts);
  }, [contacts]);

  /**
   * Create a new contact from values.
   *
   * @param {array} values
   */
  const addContact = (values) => {
    let payload = { ...values, ...{ contactable: contactable } };

    coreApi
      .post("contacts", payload)
      .then((res) => {
        setContactsState([...contactsState, res.data.contact]);
        notification.success({ message: res.data.message });
      })
      .catch((error) => console.log(error));
  };

  /**
   * Add an existing contact to the contactable.
   *
   * @param {array} values
   */
  const addExistingContact = (values) => {
    let payload = { ...values, ...{ contactable: contactable } };

    coreApi
      .put("contacts/" + values?.contact_id + "/assign-to-contactable", payload)
      .then((res) => {
        notification.success({ message: res.data.message });
        setContactsState([...contactsState, res.data.contact]);
      })
      .catch((error) => console.log(error));
  };

  /**
   * Update a contact from values.
   *
   * @param {array} values
   */
  const updateContact = (values) => {
    let payload = { ...values, ...{ contactable: contactable } };

    coreApi
      .put("contacts/" + values?.contact_id, payload)
      .then((res) => {
        let contacts = contactsState;

        let index = contacts.findIndex((el) => el.id === res.data?.contact?.id);
        contacts[index] = res.data?.contact;
        setContactsState([...contacts]);

        notification.success({ message: res.data.message });
      })
      .catch((error) => console.log(error));
  };

  /**
   * Delete a contact from the contactable.
   *
   * @param {array} values
   */
  const deleteContactRelation = (values) => {
    let payload = { ...values, ...{ contactable: contactable } };

    coreApi
      .delete("contacts/" + values?.contact_id + "/unassign-contactable", {
        data: payload,
      })
      .then((res) => {
        notification.success({ message: res.data.message });
        let newContacts = contactsState.filter((contact) => contact?.id !== values.contact_id);
        setContactsState(newContacts);
      })
      .catch((error) => console.log(error));
  };

  /**
   * Delete the selected contact.
   *
   * @param {array} contact
   */
  const deleteContact = (contact) => {
    coreApi
      .delete("contacts/" + contact?.id)
      .then((res) => {
        let deleted = contactsState.filter((item) => item?.id !== contact?.id);
        setContactsState([...deleted]);
        notification.success({
          message: t("common.recordDeleted"),
        });
      })
      .catch((error) => console.log(error));
  };

  return (
    <React.Fragment>
      <List
        size="small"
        header={
          <div
            style={{
              height: "100%",
              background: "#fafafa",
              margin: "-12px",
              paddingTop: "12px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Row>
              <Col span={3}>
                <h4 style={{ textAlign: "center" }}>{t("contacts.name")}</h4>
              </Col>
              <Col span={3}>
                <h4 style={{ textAlign: "center" }}>{t("contacts.email")}</h4>
              </Col>
              <Col span={2}>
                <h4 style={{ textAlign: "center" }}>{t("contacts.phone")}</h4>
              </Col>
              <Col span={4}>
                <h4 style={{ textAlign: "center" }}>{t("contacts.companyName")}333</h4>
              </Col>
              <Col span={6}>
                <h4 style={{ textAlign: "center" }}>{t("contacts.contactType")}</h4>
              </Col>
              <Col span={4}>
                <h4 style={{ textAlign: "center" }}>{t("contacts.notesInfo")}</h4>
              </Col>
              <Col span={2}>
                <h4 style={{ textAlign: "center" }}>{t("common.actions")}</h4>
              </Col>
            </Row>
          </div>
        }
        itemLayout="horizontal"
        dataSource={contactsState}
        renderItem={(item) => {
          let unitId = {
            match: {
              params: {
                id: item?.id,
              },
            },
          };

          return (
            <List.Item>
              <Contact
                props={allContacts ? unitId : props}
                contact={item}
                updateContact={updateContact}
                deleteContact={deleteContact}
                deleteContactRelation={deleteContactRelation}
                tags={tags}
              ></Contact>
            </List.Item>
          );
        }}
      ></List>

      {contactable?.id && (
        <ContactAddForm
          ctAddForm
          onFinishAction={addContact}
          addExisting={addExistingContact}
          tags={tags}
          contactable={contactable}
        ></ContactAddForm>
      )}
    </React.Fragment>
  );
};

export default ContactsPane;
